<template>
  <div>
    <img src="../../../public/images/qt/join/join_01.jpg" width="100%" height="100%">
<!--    <div class="location">招聘岗位</div>-->
<!--    <div class="locations">-->
<!--      我们根据候选人的具体能力水平面谈协商，-->
<!--      <div>给出具有市场竞争力的薪资</div>-->
<!--    </div>-->
<!--    <div class="tab">-->
<!--      <van-tabs v-model="active" type="card" background="#F1ECEC">-->
<!--        <van-tab title="研发工程师">-->
<!--          <div class="tab_box">-->
<!--            岗位要求 | 岗位职责-->
<!--            <p>暂无招聘计划，敬请留意</p>-->
<!--          </div>-->
<!--        </van-tab>-->
<!--        <van-tab title="产品经理">-->
<!--          <div class="tab_box">-->
<!--            岗位要求 | 岗位职责-->
<!--            <p>暂无招聘计划，敬请留意</p>-->
<!--          </div>-->
<!--        </van-tab>-->
<!--        <van-tab title="项目经理">-->
<!--          <div class="tab_box">-->
<!--            岗位要求 | 岗位职责-->
<!--            <p>暂无招聘计划，敬请留意</p>-->
<!--          </div>-->
<!--        </van-tab>-->
<!--        <van-tab title="测试工程师">-->
<!--          <div class="tab_box">-->
<!--            岗位要求 | 岗位职责-->
<!--            <p>暂无招聘计划，敬请留意</p>-->
<!--          </div>-->
<!--        </van-tab>-->
<!--      </van-tabs>-->
<!--    </div>-->

    <img src="../../../public/images/qt/join/join_02.jpg" width="100%" height="100%">
    <img src="../../../public/images/qt/join/join_03.jpg" width="100%" height="100%">
  </div>
</template>

<script>
export default {
  name: "加入我们"
}
</script>

<style scoped>
.location {
  font-size: 26px;
  text-align: center;
}

.locations {
  font-size: 12px;
  margin: auto;
  text-align: center;
}

.van-tabs {
  padding-top: 5%;
  height: 160px;
}

.tab {
  border-radius: 10px;
  margin: auto;
  width: 90%;
  height: 160px;
  background-color: #F5F5F5;

}

.tab_box {
  color: #B9B9B9;
  font-size: 13px;
  padding: 5%;
}

</style>